
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {FiDownload, FiEye} from "react-icons/fi";
import BackBtnComponent from "../Components/backBtn.component";
import {useNavigate} from "react-router-dom";

import jsPDF from 'jspdf';


const KScheme=()=>{

    const [response, setResponse] = useState('');
    const [isLoading,setIsLoading] = useState(false)

    const [selectedOption, setSelectedOption] = useState('K');
    const [selectedOption1, setSelectedOption1] = useState('');

    const [html, setHtml] = useState("");

    const navigate = useNavigate();

    const dataArray = [
        // { title: "Select Program / Course", value: "" },
        { title: "AA-Diploma In Architecture Assistantship", value: "AA" },
        { title: "AE-Diploma In Automobile Engineering.", value: "AE" },
        { title: "AI-Diploma In Artificial Intelligence", value: "AI" },
        { title: "AL-Diploma In Agricultural Engineering", value: "AL" },
        { title: "AN-Diploma In Artificial Intelligence and Machine Learning", value: "AN" },
        { title: "AO-Diploma In Automation and Robotics", value: "AO" },
        { title: "AT-Diploma In Architecture", value: "AT" },
        { title: "BD-Diploma In Cloud Computing and Big Data", value: "BD" },
        { title: "CE-Diploma In Civil Engineering", value: "CE" },
        { title: "CH-Diploma In Chemical  Engineering", value: "CH" },
        { title: "CM-Diploma In Computer Technology", value: "CM" },
        { title: "CO-Diploma In Computer Engineering", value: "CO" },
        { title: "CR-Diploma In Civil & Rural Engineering", value: "CR" },
        { title: "CS-Diploma In Construction Technology", value: "CS" },
        { title: "CW-Diploma In Computer Science & Engineering", value: "CW" },
        { title: "DC-Diploma In Fashion & Clothing Technology", value: "DC" },
        { title: "DD-Diploma In Dress Designing & Garment Manufacturing", value: "DD" },
        { title: "DE-Diploma In Digital Electronics", value: "DE" },
        { title: "DS-Diploma In Data Sciences", value: "DS" },
        { title: "EE-Diploma In Electrical Engineering", value: "EE" },
        { title: "EJ-Diploma In Electronics & Tele-communication Engg.", value: "EJ" },
        { title: "EP-Diploma In Electrical Power System", value: "EP" },
        { title: "ET-Diploma In Electronics & Communication Engg.", value: "ET" },
        { title: "EX-Diploma In Electronics Engineering", value: "EX" },
        { title: "FC-Diploma In Food Technology", value: "FC" },
        { title: "HA-Diploma In Computer Hardware & Maintenance", value: "HA" },
        { title: "HM-Diploma In Hotel Management & Catering Technology", value: "HM" },
        { title: "IC-Diploma In Instrumentation & Control", value: "IC" },
        { title: "IE-Diploma In Industrial Electronics", value: "IE" },
        { title: "IF-Diploma In Information Technology", value: "IF" },
        { title: "IH-Diploma In Computer Science & Information Technology", value: "IH" },
        { title: "IS-Diploma In Instrumentation", value: "IS" },
        { title: "IX-Diploma In Interior Design & Decoration", value: "IX" },
        { title: "IZ-Diploma In Interior Design", value: "IZ" },
        { title: "LE-Diploma In Civil & Environmental Engineering", value: "LE" },
        { title: "ME-Diploma In Mechanical Engineering", value: "ME" },
        { title: "MK-Diploma In Mechatronics", value: "MK" },
        { title: "ML-Diploma In Medical Laboratory Technology", value: "ML" },
        { title: "MS-Diploma In Mining & Mine Surveying", value: "MS" },
        { title: "MU-Diploma In Medical Electronics", value: "MU" },
        { title: "MZ-Diploma In Mining Engineering", value: "MZ" },
        { title: "PG-Diploma In Production Engineering", value: "PG" },
        { title: "PN-Diploma In Printing Technology", value: "PN" },
        { title: "PO-Diploma In Polymer Technology", value: "PO" },
        { title: "SC-Diploma In Surface Coating Technology", value: "SC" },
        { title: "TC-Diploma In Textile Technology", value: "TC" },
        { title: "TE-Diploma In Electronics & Computer Engg.", value: "TE" },
        { title: "TR-Diploma In Travel and Tourism", value: "TR" },
        { title: "TX-Diploma In Textile Manufactures", value: "TX" }
    ];

    function handleSelectChange1(event) {
        setSelectedOption1(event.target.value);
        setResponse("")
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const response = await fetch('https://api.universal.cwipedia.com/msbte/k-scheme', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    scheme: selectedOption,
                    course_code: selectedOption1
                })
            });

            const result = await response.text();

            const parser = new DOMParser();
            const doc = parser.parseFromString(result, 'text/html');
            const courseRows = doc.querySelectorAll('tr[id^="tr"]');
            const extractedData = [];
            courseRows.forEach(row => {
                const courseTitleElement = row.querySelector('a[style="color:blue;"]');
                const courseTitle = courseTitleElement.textContent.trim();
                const abbreviation = row.querySelector('.cntr').textContent.trim();
                const courseCodeElement = courseTitleElement.parentElement.nextElementSibling;
                const courseCode = courseCodeElement.textContent.trim();

                // Extracting the argument of show_pdf function call
                const showPdfFunctionCall = courseTitleElement.getAttribute('onclick');
                const matches = showPdfFunctionCall.match(/show_pdf\('(\d+)'\)/);
                const argumentValue = matches ? matches[1] : null;

                extractedData.push({
                    courseTitle,
                    abbreviation,
                    courseCode,
                    argumentValue
                });
            });
            setResponse(extractedData)
            setIsLoading(false)
            console.log(extractedData)
            // setSelectedOption1(null)
        } catch (error) {
            console.error(error);
            // handle the error as needed
        }
    };

    const handleDownload = async (argu) => {
        try {
            const response = await fetch('https://api.universal.cwipedia.com/msbte/download-syllabus', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ subject_course: argu, programe_code:""  }),
            });
            // debugger
            const data = await response.text()


            navigate('/k-scheme/view', { state:data });


        } catch (error) {
            console.error('An error occurred:', error);
        }
    };


    return(
        <>
            <div>

                <Helmet>
                    <title>K Scheme Syllabus | MSBTE </title>
                    <meta
                        name="description"
                        content="Boost Your MSBTE Exam Preparation with These K Scheme Syllabus | MSBTE Lab K Scheme Material  for Practice"
                    />
                    <meta
                        name="keywords"
                        content="MSBTE sample question paper, MSBTE model papers, MSBTE exam preparation, practice papers, MSBTE diploma exams, MSBTE study material."
                    />
                </Helmet>

                <div className="hero  bg-base-200">
                    <div className="hero-content flex-col lg:flex-row">
                        <div className="card w-[2000px] max-sm:w-full  shadow-2xl bg-base-100">
                            <div className="card-body">
                                <BackBtnComponent/>

                                <p className="text-center text-2xl font-bold">K Scheme Syllabus</p>

                                <form onSubmit={handleSubmit}>
                                    <div className="form-control w-full">
                                        <label className="label">
                                            <span className="label-text">Select Scheme</span>
                                        </label>
                                        <input
                                            type="text"
                                            value={selectedOption}
                                            onChange={(e) => setSelectedOption("K")}
                                            placeholder="Question"
                                            className="input input-bordered rounded-[2px] w-full"
                                            disabled
                                        />
                                    </div>
                                    <div className="form-control w-full">
                                        <label className="label">
                                            <span className="label-text">Select Subject</span>
                                        </label>
                                        <select id="modalSem" name="modalSem"
                                                value={selectedOption1} onChange={handleSelectChange1} className="select select-bordered w-full "  required>
                                            <option  defaultValue>Select Branch</option>
                                            {
                                                dataArray.map((item,key)=>(
                                                    <option  value={item.value}>{item.title}</option>
                                                ))
                                            }
                                        </select>
                                        {/*<input type="text"   className="input input-bordered" />*/}
                                    </div>

                                    {!isLoading?<input type="submit" className="btn w-full mt-4 " value="Search"/>:<button className="btn mt-4 w-full loading"></button>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    response?<div className="overflow-x-auto ">
                        <table className="table mt-4 table-compact w-full">
                            {/* head */}
                            <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Subject</th>
                                <th>Download</th>
                            </tr>
                            </thead>
                            <tbody>
                            {/* row 1 */}
                            {
                                response.map((item,key)=>(
                                    <tr>
                                        <th>{key+1}</th>
                                        <tr ><p className="mt-auto mb-auto pt-[20px]">
                                            {item.courseTitle}
                                        </p></tr>
                                        <td>
                                            <div className="btn-grp gap-2">
                                                <button className="btn btn-ghost" onClick={()=>(handleDownload(item.argumentValue))}><FiDownload className="text-2xl"/></button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>:null
                }

            </div>
        </>
    )
}
export default KScheme;
