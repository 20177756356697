import {Link} from "react-router-dom";

const NotFoundComponent=()=>{

    return(
        <>
            <div className="hero min-h-screen bg-base-100">
                <div className="hero-content text-center">
                    <div className="max-w-md">
                        <h1 className="text-5xl font-bold">404 | Not Found</h1>
                        <Link to="/" className="btn btn-primary">Home</Link>
                    </div>
                </div>
            </div>
        </>
    )
}
export default NotFoundComponent;
