import {useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import LoadingComponent from "../Components/loading.component";
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {MdDownloadForOffline} from "react-icons/md";


const ViewPdfPage=()=>{
    const [loading, setLoading] = useState(false);

    const location = useLocation();
    const pdfData = location.state.paperLink;
    const subName = location.state.subName;
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(null);

    function handleNextPage() {
        setPageNumber(pageNumber + 1);
    }

    function handlePrevPage() {
        setPageNumber(pageNumber - 1);
    }

    function handleLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    useEffect(() => { pdfjs.GlobalWorkerOptions.workerSrc =`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;});

    function handleLoadError(error) {
        // console.error(error);
    }


    return(
        <>

            <div className="flex flex-col items-center w-full h-full overflow-x-scroll">

                <div className="fixed z-20 bottom-0 mb-5 text-center">
                    <p className=" text-black mb-2  font-bold">{subName}</p>
                    <div className="btn-group ">
                        <button onClick={handlePrevPage} disabled={pageNumber === 1} className="btn btn-sm disabled:bg-gray-400 disabled:text-black">
                            Previous Page
                        </button>
                        <button onClick={handleNextPage} disabled={pageNumber === numPages} className="btn btn-sm disabled:bg-gray-400 disabled:text-black">
                            {loading ? (
                                <LoadingComponent/>
                            ) : (
                                'Next Page'
                            )}
                        </button>
                    </div>
                    {numPages && (
                        <p className="text-sm text-black mt-2">
                            Page {pageNumber} of {numPages}
                        </p>
                    )}
                    <a href={pdfData}  className=" btn btn-sm captilize mt-2 gap-2" download={pdfData}>
                        <MdDownloadForOffline className="text-2xl "/>
                        Download
                    </a>
                </div>



                <div className="w-full max-w-screen-sm h-full">
                    <div className="flex justify-center items-center h-full">
                        <Document
                            file={pdfData}
                            onLoadSuccess={handleLoadSuccess}
                            onLoadError={handleLoadError}
                            className="w-full"
                            noData={<LoadingComponent/>}
                            loading={<LoadingComponent/>}

                            style={{maxWidth: '100%', height: 'auto' }}
                        >
                            <Page
                                pageNumber={pageNumber}
                                className="w-full"
                                style={{ maxWidth: '100%', height: 'auto' }}
                            />
                        </Document>
                    </div>
                </div>
    </div>
        </>
    )
}
export default ViewPdfPage;
