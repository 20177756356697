
 import logo from "../assets/logo.png"
 import {AiFillInstagram, AiFillLinkedin, AiFillTwitterCircle, AiFillYoutube} from "react-icons/ai";

 import {Link} from "react-router-dom";
const FooterComponent=()=>{

    const d = new Date();
    const year = d.getFullYear();

    // console.log(data.main)

    return(
        <>
            <footer className="footer footer-center p-4 bg-neutral  text-base-content" style={{color:"white"}}>
                <div>
                    <p>MSBTE Mastery V2.O (Beta) by CWIPEDIA</p>
                    {/*<p>Copyright © {year} - All right reserved by CWIPEDIA</p>*/}
                </div>
            </footer>
        </>
    )
}
export default FooterComponent;
