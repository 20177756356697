import {Link} from "react-router-dom";

const FeatureComponent=()=>{
    const AddOn=[
        {
            tag:"new",
            tagn:"latest",
            title:"K Scheme Syllabus",
            url:"/k-scheme",
            content:"Latest MSBTE K Syllabus for Practice"
        },
        {
            tag:"new",
            title:"MSBTE Syllabus",
            url:"/syllabus",
            content:"Boost Your MSBTE Exam Preparation with These latest syllabus | MSBTE Syllabus for Practice"
        },
        {
            tag:"new",
          title:"Modal Answer Paper",
          url:"/modelAnswerPaper",
          content:"Boost Your MSBTE Exam Preparation with These Sample Question Papers | MSBTE Model Papers for Practice"
        },
        {
            tag:"new",
            title:"Sample Question Paper",
            url:"/sampleQuestionPaper",
            content:"Boost Your MSBTE Exam Preparation with These Sample Question Papers | MSBTE Model Papers for Practice"
        },
        {
            tag:"trending",
            title:"E Content",
            url:"/eContent",
            content:"Boost Your MSBTE Exam Preparation with These Sample Question Papers | MSBTE Model Papers for Practice"
        },
        {
            tag:"trending",
            title:"MSBTE Lab Manuals",
            url:"/labManuals",
            content:"Boost Your MSBTE Exam Preparation with These Sample Question Papers | MSBTE Model Papers for Practice"
        },
        {
            tag:"trending",
            title:"Learning Material ",
            url:"/labManuals",
            content:"Boost Your MSBTE Exam Preparation with These Sample Question Papers | MSBTE Model Papers for Practice"
        },
        // {
        //     title:"model",
        //     url:"/test",
        //     content:"Provident cupiditate voluptatem et in. Quaerat fugiat ut assumenda excepturi "
        // },
        // {
        //     title:"",
        //     url:"",
        //     content:""
        // },
    ]
    return(
        <>
            <div className="hero p-4 bg-base-200">
                <div className=" flex-col lg:flex-row">
                    <div className="flex grid place-items-center justify-center max-sm:grid place-content-center grid-cols-4 gap-4 max-sm:grid-cols-1 max-md:grid-cols-4">
                        {
                            AddOn.map((item,key)=>(
                                <div className="place-self-center">
                                    <div key={key} className={`card c-card rounded-[10px] bg-base-100 shadow-xl`}>
                                        <Link to={item.url} className="card-body">
                                            <h2 className="card-title">
                                                {item.title}
                                                {item.tagn?
                                                    <div className="badge badge-error text-white">{item.tagn}</div>:
                                                    <div className="badge badge-accent text-white">{item.tag}</div>
                                                }
                                            </h2>
                                            <p>{item.content}</p>
                                            <div className="card-actions justify-end">
                                                <Link to={item.url} className="btn btn-accent">View</Link>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </div>
            </div>

        </>
    )
}
export default FeatureComponent;
