import {Helmet} from "react-helmet";
import {MdDownloadForOffline} from "react-icons/md";
import {AiFillEye} from "react-icons/ai";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import BackBtnComponent from "../Components/backBtn.component";

const SyllabusPage=()=>{



    const [response, setResponse] = useState('');
    const [isLoading,setIsLoading] = useState(false)

    const [selectedOption, setSelectedOption] = useState('');
    const [code,setCodde] =useState("")

    const [paperLink,setPaperLink]=useState("")
    const [paperLinkNot,setPaperLinkNot]=useState("")
    const [subName,setSubName]=useState("")

    function handleCode(event) {
        setPaperLinkNot("")
        setPaperLink("")
        setCodde(event.target.value)
    }


    const handleSubmit =async (e) => {
        e.preventDefault(); // prevent the default form submission behavior
        setIsLoading(true)
        const formData = new FormData(e.target);
        // console.log(formData)
        const response = await fetch('https://api.universal.cwipedia.com/msbte/syllabus', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Codtxt1:code,
            }),
        });
        const data = await response.text();
        setResponse(data);
        const el = document.createElement('html');
        el.innerHTML = data;

        setSubName(el.querySelector('a').innerText)
        // reg
        const linkRegex = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/;

        const linkMatch = data.match(linkRegex);
        const link = linkMatch ? linkMatch[2] : '';
        setPaperLink(link)

        const notFoundRegex = /<p.*?>(.*?)<\/p>/;

        const notFoundMatch = data.match(notFoundRegex);
        const notFoundText = notFoundMatch ? notFoundMatch[1] : '';
        console.log('Not Found Text:', notFoundText);
        setPaperLinkNot(notFoundText)
        setSelectedOption('');
        setCodde("")
        setIsLoading(false)

    };

    const navigate=useNavigate();
    function handleViewPdf() {
        navigate('/view', { state: { paperLink,subName } });
    }


    return(
        <>

            <Helmet>
                <title>MSBTE Mastery - Syllabus</title>
                <meta
                    name="description"
                    content="Boost Your MSBTE Exam Preparation with These Syllabus | MSBTE Model Syllabus for Practice"
                />
                <meta
                    name="keywords"
                    content="MSBTE sample question paper, MSBTE model papers,Syllabus,MSBTE Syllabus, I Scheme Syllabus, MSBTE exam preparation, practice papers, MSBTE diploma exams, MSBTE study material."
                />
            </Helmet>
            <div className="hero min-h-screen bg-base-200">

                <div className="hero-content flex-col lg:flex-row-reverse">
                    <div className="text-center  lg:text-left">
                        <h1 className="text-5xl font-bold">Syllabus / CURRICULUM </h1>
                        <p className="py-6">Boost Your MSBTE Exam Preparation with These Syllabus | MSBTE Model Syllabus for Practice</p>
                        <BackBtnComponent/>
                    </div>
                    <div className="card w-2/5	max-sm:w-full max-lg:w-full flex-shrink-0  shadow-2xl bg-base-100">
                        <div className="card-body">
                            <p className="text-center text-2xl font-bold">MSBTE Syllabus </p>
                            <form onSubmit={handleSubmit}>
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Enter Paper Code</span>
                                    </label>
                                    <input type="text" id="Codtxt1" name="Codtxt1"
                                           value={code} onChange={handleCode} placeholder="Enter Paper Code" className="input input-bordered" required/>
                                </div>
                                {!isLoading?<input type="submit" className="btn w-full mt-4 " value="Search"/>:<button className="btn mt-4 w-full loading"></button>
                                }
                            </form>

                            {
                                paperLink?<div>
                                    <div className="alert alert-success shadow-lg">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                                            <span>Your <span className="font-bold text-[white]">{subName}</span>  <b>syllabus</b> is ready to download and view
                                        </span>
                                        </div>
                                    </div>
                                    <div className="alert mt-2 alert-success ">
                                        <div className="flex flex-col w-full lg:flex-row">
                                            <div className="grid flex-grow  card  rounded-box place-items-center">
                                                <a href={paperLink}  className=" btn  gap-2" download={paperLink}>
                                                    <MdDownloadForOffline className="text-4xl"/>
                                                    Download
                                                </a>
                                            </div>
                                            <div className="divider lg:divider-horizontal">OR</div>
                                            <div className="grid flex-grow  card  rounded-box place-items-center">
                                                <button onClick={handleViewPdf} className="btn gap-2">
                                                    <AiFillEye className="text-4xl"/>
                                                    <span>View</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>: null
                            }
                            {
                                paperLinkNot=="---No Paper Code Curriculum Found---"?<div className="alert alert-error shadow-lg">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                                        <span >Error! <b>{paperLinkNot}</b></span>
                                    </div>
                                </div>:null
                            }

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default SyllabusPage;
