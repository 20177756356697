import React, {useState} from "react";
import {Helmet} from "react-helmet";
import {FiDownload, FiEye} from "react-icons/fi";
import BackBtnComponent from "../Components/backBtn.component";

const LabManualPage=()=>{
    // https://curriculum.msbte.ac.in/msbteacmon/curdev/outer/ajax/get_lm_ajax.php?semester=1&ctype=L

    const [response, setResponse] = useState('');
    const [isLoading,setIsLoading] = useState(false)

    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOption1, setSelectedOption1] = useState('');



    function handleSelectChange(event) {
        setSelectedOption(event.target.value);
        setResponse("")
    }
    function handleSelectChange1(event) {
        setSelectedOption1(event.target.value);
        setResponse("")
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        // https://api.universal.cwipedia.com
        try {
            const response = await fetch('https://api.universal.cwipedia.com/msbte/e-content', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ctype:selectedOption, semester:selectedOption1 })
            });
            const result = await response.text();
            const parser = new DOMParser();
            const doc = parser.parseFromString(result, "text/html");
            const table = doc.getElementById("customers1");
            const rows = table.getElementsByTagName("tr");
            const newData = [];
            for (let i = 1; i < rows.length; i++) {
                const cols = rows[i].getElementsByTagName("td");
                newData.push({
                    srNo: cols[0].innerText,
                    semester: cols[1].innerText,
                    fileName: cols[2].innerText,
                    downloadLink: cols[3].getElementsByTagName("a")[0].href
                });
            }
            setResponse(newData)

            // to store id
            newData.forEach((obj) => {
                const regex = /\/d\/(.+?)\//;
                const match = regex.exec(obj.downloadLink);
                if (match) {
                    obj.fileId = match[1];
                }
            })
            // console.log(newData)

            setIsLoading(false);
            setSelectedOption("")
            setSelectedOption1("")

        } catch (error) {
            console.error(error);
            // handle the error as needed
        }

    };

    return(
        <>
            <div>

                <Helmet>
                    <title>MSBTE Mastery - Lab Manuals/Learning Material </title>
                    <meta
                        name="description"
                        content="Boost Your MSBTE Exam Preparation with These Lab Manuals/Learning Material | MSBTE Lab Manuals/Learning Material  for Practice"
                    />
                    <meta
                        name="keywords"
                        content="MSBTE sample question paper, MSBTE model papers, MSBTE exam preparation, practice papers, MSBTE diploma exams, MSBTE study material."
                    />
                </Helmet>

                <div className="hero  bg-base-200">
                    <div className="hero-content flex-col lg:flex-row">
                        <div className="card w-[2000px] max-sm:w-full  shadow-2xl bg-base-100">
                            <div className="card-body">
                                <BackBtnComponent/>

                                <p className="text-center text-2xl font-bold">MSBTE Lab Manuals/Learning Material </p>

                                <form onSubmit={handleSubmit}>
                                    <div className="form-control w-full">
                                        <label className="label">
                                            <span className="label-text">Select Type</span>
                                        </label>
                                        <select id="modalSem" name="modalSem"
                                                value={selectedOption} onChange={handleSelectChange} className="select select-bordered w-full "  required>
                                            <option  defaultValue>Select Type</option>
                                            <option value="L">Lab Manual</option>
                                            <option value="ML">Marathi Lab Manual</option>
                                            <option value="M">Learning Material</option>
                                        </select>
                                        {/*<input type="text"   className="input input-bordered" />*/}
                                    </div>
                                    <div className="form-control w-full">
                                        <label className="label">
                                            <span className="label-text">Select Semester/Year</span>
                                        </label>
                                        <select id="modalSem" name="modalSem"
                                                value={selectedOption1} onChange={handleSelectChange1} className="select select-bordered w-full "  required>
                                            <option  defaultValue>Select Semester/Year</option>
                                            <option value="1">Semester 1</option>
                                            <option value="2">Semester 2</option>
                                            <option value="3">Semester 3</option>
                                            <option value="4">Semester 4</option>
                                            <option value="5">Semester 5</option>
                                            <option value="6">Semester 6</option>
                                            <option value="23">Year 2</option>
                                        </select>
                                        {/*<input type="text"   className="input input-bordered" />*/}
                                    </div>

                                    {!isLoading?<input type="submit" className="btn w-full mt-4 " value="Search"/>:<button className="btn mt-4 w-full loading"></button>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    response?<div className="overflow-x-auto ">
                        <table className="table mt-4 table-compact w-full">
                            {/* head */}
                            <thead>
                            <tr>
                                <th>Semester</th>
                                <th>Sr. No.</th>
                                <th className="flex flex-row">Subject Name</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {/* row 1 */}
                            {
                                response.map((item,key)=>(
                                    <tr>
                                        <th>{item.semester}</th>
                                        <tr>{item.srNo}</tr>
                                        <td className="whitespace-nowrap overflow-hidden !truncate">{item.fileName}</td>
                                        <td>
                                            <div className="btn-grp gap-2">
                                                <a className="btn btn-ghost" target="_blank" href={item.downloadLink}><FiEye className="text-2xl"/></a>
                                                <a className="btn btn-ghost" href={"https://drive.google.com/uc?export=download&id="+item.fileId}><FiDownload className="text-2xl"/></a>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>:null
                }

            </div>
        </>
    )
}
export default LabManualPage;
