import HeroComponent from "../Components/hero.component";
import FeatureComponent from "../Components/feature.component";

const HomePage=()=>{

    return(
       <>
           <HeroComponent/>
           <FeatureComponent/>
       </>
    )

}
export default HomePage;
