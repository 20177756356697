const submitForm = async (formData) => {
    try {
        const response = await fetch('https://msbte.org.in/portal/qpjoin.php', {
            method: 'POST',
            body: formData,
        });
        const data = await response.text();
        console.log(data); // do something with the response data
    } catch (error) {
        console.error(error);
    }
};

const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    submitForm(formData);
};

const TestPage = () => {
    return (
        <form onSubmit={handleSubmit}>
            <input type="text" name="name" />
            <input type="text" name="email" />
            <button type="submit">Submit</button>
        </form>
    );
};
export default TestPage;
