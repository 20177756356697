import {Link, useNavigate} from "react-router-dom";
import {AiFillBackward, AiFillHome} from "react-icons/ai";

const BackBtnComponent = () => {
  const navigate=useNavigate();

  return(
      <>
          <div className="btn-group text-2xl">
              <Link to="/" className="btn text-xl"><AiFillHome/></Link>
              <button onClick={()=>(navigate(-1))} className="btn text-xl"><AiFillBackward/></button>
          </div>
      </>
  )
}
export default BackBtnComponent;
