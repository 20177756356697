import logo from "../assets/msbte-mastery.png"
const HeroComponent=()=>{

    return(
        <>
            <div className="hero  bg-base-200">
                <div className="hero-content flex-col lg:flex-row">
                    <img src={logo} className="w-1/4 rounded-[25px] " />
                    <div className="max-sm:text-center">
                        <h1 className="text-5xl font-bold">MSBTE Mastery</h1>
                        <p className="py-6 text-xl">Master your MSBTE exams with MSBTE Mastery - Your Ultimate Diploma Study Resource!</p>
                        {/*<button className="btn btn-primary">Explore</button>*/}
                        {/*<img className="h-20" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />*/}
                    </div>
                </div>
            </div>
        </>
    )
}
export default HeroComponent;
