import Lottie from "lottie-react";
import anim from "../assets/98432-loading.json"
const LoadingComponent=()=>{

    return(
        <>
            <div className="hero min-h-screen bg-base-200">
                <div className="hero-content text-center">
                    <div className="max-w-md">
                        <Lottie animationData={anim}/>
                    </div>
                </div>
            </div>
        </>
    )
}
export default LoadingComponent;
