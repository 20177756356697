import {BrowserRouter as Router, Routes,Route} from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';
import FooterComponent from "./Components/footer.component";
import NavbarComponent from "./Components/navbar.component";
import HomePage from "./pages/home.page";
import ModalAnswerPage from "./pages/modalAnswer.page";
import ViewPdfPage from "./pages/viewPdf.page";
import NotFoundComponent from "./Components/notFound.component";
import SampleQuestionPage from "./pages/sampleQuestion.page";
import EContentPage from "./pages/eContent.page";
import LabManualPage from "./pages/labManual.page";
import SyllabusPage from "./pages/syllabus.page";
import TestPage from "./pages/test.page";
import BottomNavbarComponent from "./Components/bottomNavbar.component";
import KScheme from "./Components/kScheme.component";
import ViewSyllabus from "./pages/viewSyllabus";
import {useEffect, useState} from "react";

function App() {
    const [isContextMenuOpen, setContextMenuOpen] = useState(false);


    // eslint-disable-next-line no-undef
    useEffect(() => {
        if (isContextMenuOpen) {
            // alert('Context menu is open.');
        }
    }, [isContextMenuOpen]);

    const handleContextMenu = (e) => {
        e.preventDefault(); // Prevent the default context menu behavior
        setContextMenuOpen(!isContextMenuOpen); // Toggle the context menu state
    };
    return (
    <div className="App"  onContextMenu={handleContextMenu}>
        <Router>
            <NavbarComponent/>
            <Routes>
                <Route path="/" exact element={<HomePage/>}/>
                <Route path="/syllabus" exact element={<SyllabusPage/>}/>
                <Route path="/modelAnswerPaper" exact element={<ModalAnswerPage/>}/>
                <Route path="/sampleQuestionPaper" exact element={<SampleQuestionPage/>}/>
                <Route path="/eContent" exact element={<EContentPage/>}/>
                <Route path="/labManuals" exact element={<LabManualPage/>}/>
                <Route path="/view" exact element={<ViewPdfPage/>}/>
                <Route path="/test" exact element={<TestPage/>}/>
                <Route path="*" exact element={<NotFoundComponent/>}/>
                <Route path="/k-scheme" exact element={<KScheme/>}/>
                <Route path="/k-scheme/view" exact element={<ViewSyllabus/>}/>
            </Routes>
            <FooterComponent/>
            {/*<BottomNavbarComponent/>*/}
        </Router>
    </div>
  );
}

export default App;
