import {useLocation} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import ReactDOMServer from 'react-dom/server';
import jsPDF from 'jspdf';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

const ViewSyllabus=()=>{
    const [loading, setLoading] = useState(false);


    const location = useLocation();
    const data = location.state;

    const iframeRef = useRef(null);

    const handlePrint = () => {
        if (iframeRef.current) {
            const iframeWindow = iframeRef.current.contentWindow;
            iframeWindow.print();
        }
    };


    return(
        <>

            <div className="m-4">
                <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2">
                    <button
                        onClick={handlePrint}
                        className="btn btn-accent text-white font-semibold py-2 px-4 capatilize shadow-lg"
                    >
                       Save
                    </button>
                </div>
                <iframe
                    ref={iframeRef}
                    className="w-full  aspect-video h-screen	"
                    srcDoc={data} // Assuming 'data' contains your iframe content
                    sandbox="allow-same-origin allow-scripts allow-modals"
                    title="cwipedia"
                ></iframe>
            </div>
        </>
    )
}
export default ViewSyllabus;
