import React, {useState} from "react";
import LoadingComponent from "../Components/loading.component";
import BackBtnComponent from "../Components/backBtn.component";

const EContentPage=()=>{
    const [iframeLoaded, setIframeLoaded] = useState(false);

    function handleIframeLoad() {
        setIframeLoaded(true);
    }



    return(
        <>

            {!iframeLoaded && <LoadingComponent/>}
            <div className="flex flex-col items-center justify-center">
                <BackBtnComponent/>
            <iframe
                className="w-screen h-screen overflow-auto" src={"https://econtent.msbte.ac.in/econtent/econtent_home.php"+"#zoom=100"}
                onLoad={handleIframeLoad}
                style={{ display: iframeLoaded ? 'block' : 'none' }}
                scrolling="no"
                sandbox="allow-same-origin"
            />
            </div>
        </>
    )
}
export default EContentPage;
